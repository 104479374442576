import SysFetch from "../../fetch";

const SupplieRequest = {
  createSupplie: (body) => {
    return SysFetch.post("supplie", body);
  },
  getAllSupplies: () => {
    return SysFetch.get("supplie");
  },
  getSupplieById: (id) => {
    return SysFetch.get(`supplie/${id}`);
  },
  updateSupplie: (id, body) => {
    return SysFetch.put(`supplie/${id}`, body);
  },
  deleteSupplie: (id) => {
    return SysFetch.delete(`supplie/${id}`);
  },
  getSupplierStatistics: (id) => {
    return SysFetch.get(`supplie/supplier-statistics`);
  },
};

export default SupplieRequest;
