import React from "react";
import { Outlet } from "react-router-dom";
import MainMenu from "../../components/menu/MainMenu";
import "./style.css";
import Header from "../../components/header/Header";

function HomeContainer() {
  return (
    <div className="h-screen">
      <Header />
      <div className="h-[calc(100%_-_56px)] home-container">
        {/* <div className="h-full home-left-container"> */}
          <MainMenu />
        {/* </div> */}
        <div className="h-full home-right-container">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default HomeContainer;
