import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { OrderActions } from '../../app/services/order/order.slice';

const DownIcon = () => (
  <svg viewBox="0 0 320 512" width={20} height={20}>
    <path
      fill="currentColor"
      d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"
    />
  </svg>
);

const OrderStatusFilter = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [hoveredStatus, setHoveredStatus] = useState(null);
  const dispatch = useDispatch();
  const ref = useRef(null);

  const statuses = [
    {value: "", label: 'Tất cả'},
    { value: 'completed', label: 'Hoàn thành' },
    { value: 'running', label: 'Đang chạy' },
    { value: 'pending', label: 'Đang chờ' },
    { value: 'processing', label: 'Đang xử lý' },
    { value: 'partially_cancelled', label: 'Hủy một phần' },
    { value: 'cancelled', label: 'Đã hủy' },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    dispatch(OrderActions.setStatus(selectedStatus));
    dispatch(OrderActions.getOrders());
  }, [selectedStatus, dispatch]);

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    setIsOpen(false);
  };

  const getOptionStyle = (optionValue) => {
    const baseStyle = {
      padding: "10px 12px",
      cursor: "pointer",
      transition: "background-color 0.2s",
    };

    if (selectedStatus === optionValue) {
      return {
        ...baseStyle,
        backgroundColor: "#f0f0f0",
      };
    }

    if (hoveredStatus === optionValue) {
      return {
        ...baseStyle,
        backgroundColor: "#f8f8f8",
      };
    }

    return baseStyle;
  };

  return (
    <div
      ref={ref}
      className='relative my-[10px] max-sm:w-full sm:w-[200px]'
    >
      <div
        style={{
          position: "absolute",
          top: "-10px",
          left: "10px",
          padding: "0 5px",
          backgroundColor: "white",
          fontSize: "12px",
          color: isOpen ? "#ff8900" : "#72777A",
          zIndex: 1,
        }}
      >
        Trạng thái
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          height: "50px",
          boxSizing: "border-box",
          padding: "0 12px",
          borderRadius: "8px",
          border: `1px solid ${isOpen ? `#ff8900` : `#e3e5e5`}`,
          transition: "all 0.2s",
          cursor: "pointer",
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div
          style={{
            flex: 1,
            fontSize: "14px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {statuses.find(status => status.value === selectedStatus)?.label || "Chọn trạng thái"}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: isOpen ? "#ff8900" : "#e3e5e5",
            transition: "all 0.2s",
          }}
        >
          <DownIcon />
        </div>
      </div>
      {isOpen && (
        <div
          style={{
            position: "absolute",
            top: "calc(100% + 8px)",
            left: 0,
            right: 0,
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            zIndex: 10,
          }}
        >
          {statuses.map((status) => (
            <div
              key={status.value}
              style={getOptionStyle(status.value)}
              onClick={() => handleStatusChange(status.value)}
              onMouseEnter={() => setHoveredStatus(status.value)}
              onMouseLeave={() => setHoveredStatus(null)}
            >
              {status.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OrderStatusFilter;