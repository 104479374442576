import { useNavigate } from "react-router-dom";
import MST from "../../components";
import PlugIcon from "../../images/icons/PlugIcon";
import PromotionList from "./PromotionList";
function PromotionPage() {
  const navigate = useNavigate();
  const onCreate = () => navigate("/services/promotions/create");
  return (
    <MST.Container
      title="Danh sách promotion"
      right={
        <div>
          <MST.Button
           className="max-sm:w-full"
            onClick={() => onCreate()}
          >

            <div className="flex items-center justify-center w-full">
            <div className="service-icon-create">
                <PlugIcon />
              </div>
              <p>Tạo mới Promotion</p></div>
          </MST.Button>
        </div>
      }
    >
      <div className="md:p-6">
        <PromotionList />
      </div>
    </MST.Container>
  );
}
export default PromotionPage;
