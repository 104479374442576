import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Datepicker from "react-tailwindcss-datepicker";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  getVisits,
  setCompareDate,
  setCompareWithDate,
  setDateRange,
} from "../../app/services/visit/visit.slice";
import MST from "../../components";
const VisitChart = () => {
  const dispatch = useDispatch();
  const {
    totalVisits,
    filteredData,
    compareData,
    comparisonResult,
    dateRange,
    compareDate,
    compareWithDate,
    isLoading,
  } = useSelector((state) => state.visit);

  useEffect(() => {
    dispatch(getVisits());
  }, [dispatch, dateRange, compareDate, compareWithDate]);

  const handleDateRangeChange = (newValue) => {
    dispatch(setDateRange(newValue));
  };

  const handleCompareDateChange = (newValue) => {
    dispatch(setCompareDate(newValue?.startDate));
  };

  const handleCompareWithDateChange = (newValue) => {
    dispatch(setCompareWithDate(newValue?.startDate));
  };
  const combinedData = filteredData?.dailyData?.map((item) => ({
    date: item?.date,
    count: item?.count,
    compareCount:
      compareData && compareData?.date === item?.date
        ? compareData?.count
        : null,
  }));
  return (
    <MST.Container title="Thống kê lượt truy cập">
     
      {isLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 gap-6 mb-8 md:grid-cols-2">
            <div className="p-4 bg-blue-100 rounded-lg">
              <p className="text-lg font-semibold text-blue-800">
                Tổng số lượt truy cập
              </p>
              <p className="text-3xl font-bold text-blue-600">
                {totalVisits?.toLocaleString()}
              </p>
            </div>
            <div className="p-4 bg-green-100 rounded-lg">
              <p className="text-lg font-semibold text-green-800">
                Lượt truy cập trong khoảng thời gian đã chọn
              </p>
              <p className="text-3xl font-bold text-green-600">
                {filteredData?.totalVisits?.toLocaleString()}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-6 mb-8 md:grid-cols-2">
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Khoảng thời gian
              </label>
              <Datepicker
                value={dateRange}
                onChange={handleDateRangeChange}
                showShortcuts={true}
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                So sánh giữa hai ngày
              </label>
              <div className="flex space-x-2">
                <Datepicker
                  useRange={false}
                  asSingle={true}
                  value={
                    compareDate
                      ? { startDate: compareDate, endDate: compareDate }
                      : null
                  }
                  onChange={handleCompareDateChange}
                />
                <Datepicker
                  useRange={false}
                  asSingle={true}
                  value={
                    compareWithDate
                      ? { startDate: compareWithDate, endDate: compareWithDate }
                      : null
                  }
                  onChange={handleCompareWithDateChange}
                />
              </div>
            </div>
          </div>
          {comparisonResult && (
            <div className="p-4 mb-8 bg-yellow-100 rounded-lg">
              <p className="text-lg font-semibold text-yellow-800">
                Kết quả so sánh
              </p>
              <p className="text-xl">
                Sự thay đổi: {comparisonResult?.difference} lượt truy cập (
                {comparisonResult?.percentageChange}%)
              </p>
              <p className="text-xl">
                {comparisonResult?.increase ? "Tăng" : "Giảm"}
              </p>
            </div>
          )}
          <div className="mb-8 rounded-lg md:p-4 bg-gray-50">
            <ResponsiveContainer width="100%" height={400} minWidth={350}>
              <LineChart data={combinedData} width={'100%'}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="count"
                  stroke="#3b82f6"
                  strokeWidth={2}
                  name="Lượt truy cập"
                  dot={{ r: 4 }}
                />
                {compareData && (
                  <Line
                    type="monotone"
                    dataKey="compareCount"
                    stroke="#10b981"
                    strokeWidth={2}
                    name="Lượt truy cập ngày so sánh"
                    dot={{ r: 4 }}
                  />
                )}
              </LineChart>
            </ResponsiveContainer>
          </div>
        </>
      )}
    </MST.Container>
  );
};

export default VisitChart;
