import React from "react";
import "./style.css";

function Input(props) {
  const {
    containerClassName,
    errorContainerClassName,
    errorTextClassName,
    errorMessage,
    valueNumber = false,
    type,
    min,
    ...otherPropInput
  } = props;
  const initValue = valueNumber ? 0 : "";

  return (
    <div className={`${containerClassName || ""}`}>
      <div
        className={`input-container ${
          props?.errorMessage
            ? "input-container-has-error"
            : "input-container-no-error"
        }`}
        style={{
          ...props.style,
        }}
      >
        {props.left && (
          <div
            className="input-left"
            style={{
              backgroundColor: props?.disabled ? "#FAFAFA" : "white",
            }}
          >
            {props.left}
          </div>
        )}
        <input
          className="w-full h-full outline-none input-content"
          style={{
            ...props.inputStyle,
          }}
          type={type || "text"}
          placeholder={otherPropInput.placeholder || ""}
          value={otherPropInput.value || initValue}
          onChange={otherPropInput.onChange || (() => {})}
          disabled={props?.disabled}
          min={type === "number" ? min : undefined}
          {...otherPropInput}
        />
        {props.right && (
          <div
            className="input-right"
            style={{
              backgroundColor: props?.disabled ? "#FAFAFA" : "white",
              height: "100%",
            }}
          >
            {props.right}
          </div>
        )}
      </div>

      {props?.errorMessage && (
        <div className={`text-right ${errorContainerClassName}`}>
          <span className={`input-error-message ${errorTextClassName}`}>
            {props?.errorMessage}
          </span>
        </div>
      )}
    </div>
  );
}

export default Input;