import React from "react";
import MST from "../../components";
import SearchIcon from "../../images/icons/SearchIcon";
import { useDispatch, useSelector } from "react-redux";

import { NewsActions, NewsSelectors } from "../../app/services/news/news.slice";

function NewsSearch() {
  const dispatch = useDispatch();

  const keySearch = useSelector(NewsSelectors.keySearch);

  return (
    <div className="search-service-container">
      <MST.Input
        value={keySearch}
        onChange={(e) => {
          dispatch(NewsActions.setKeySearch(e.target.value));
        }}
        placeholder={"Nhập tiêu đề tin"}
        right={
          <div className="w-16 service-search-btn">
            <SearchIcon />
          </div>
        }
      />
    </div>
  );
}

export default NewsSearch;
