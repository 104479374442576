import React from "react";
import "./style.css";

function Title({ children }) {
  return (
    <div className="flex flex-grow w-full">
      <h2 className="title-content max-sm:text-start! text-nowrap">{children}</h2>
    </div>
  );
}

export default Title;
