import { useNavigate } from "react-router-dom";
import MST from "../../components";
import PlugIcon from "../../images/icons/PlugIcon";
import VoucherList from "./VoucherList";
import "./style.css";
function VoucherPage() {
  const navigate = useNavigate();
  const onCreate = () => navigate("/services/vouchers/create");

  return (
    <MST.Container
      title="Danh sách voucher"
      right={
        <div>
          <MST.Button
            className="max-sm:w-full"
            onClick={() => onCreate()}
            icon={
              <div className="voucher-icon-create">
                <PlugIcon />
              </div>
            }
          >
            Tạo mới voucher
          </MST.Button>
        </div>
      }
    >
      <div className="voucher-content md:p-6">
        <VoucherList />
      </div>
    </MST.Container>
  );
}
export default VoucherPage;
