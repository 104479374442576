import { useEffect, useState } from "react";
import MST from "../../components";
import ProviderSelect from "./components/ProviderSelect";
import ClassifySelect from "./components/ClassifySelect";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  ServiceImportActions,
  ServiceImportSelectors,
} from "../../app/services/service-import/service-import.slice";
import CircularProgress from "@mui/material/CircularProgress";
import SearchService from "./components/SearchService";
import FilterByPlatform from "./components/FilterByPlatform";

const ServiceImport = () => {
  const isFetching = useSelector(ServiceImportSelectors.isFetching);
  const isSubmitting = useSelector(ServiceImportSelectors.isSubmitting);
  const filter = useSelector(ServiceImportSelectors.filter);
  const serviceImportListOrigin = useSelector(
    ServiceImportSelectors.serviceImportList
  );
  const serviceImportList = useSelector(
    ServiceImportSelectors.serviceImportFilterList
  );
  const [checkedItems, setCheckedItems] = useState({});
  const [checkedGroups, setCheckedGroups] = useState({});
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [vipPrices, setVipPrices] = useState({});
  const [expandedPlatforms, setExpandedPlatforms] = useState({});
  const [statusSelect, setStatusSelect] = useState({
    provider: {
      name: "",
      id: "",
    },
    classify: "",
  });
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(ServiceImportActions.setServiceImportList({}));
    };
  }, []);
  useEffect(() => {
    if (serviceImportListOrigin) {
      const increases = { 0: 30, 1: 25, 2: 20, 3: 15 };
      const defaultPrices = {};

      Object.keys(serviceImportListOrigin).forEach((platform) => {
        serviceImportListOrigin[platform].forEach((service) => {
          const basePrice = parseFloat(service.price) || 0;
          defaultPrices[service.service] = Object.keys(increases).reduce(
            (vipAcc, level) => {
              const increase = increases[level];
              vipAcc[level] = {
                percentage: increase,
                price: Math.ceil(basePrice * (1 + increase / 100)),
              };
              return vipAcc;
            },
            {}
          );
        });
      });

      setVipPrices(defaultPrices);
    }
  }, [serviceImportListOrigin]);
  useEffect(() => {
    dispatch(ServiceImportActions.getAllServiceFilterSearchOrPlatForm());
  }, [filter]);
  useEffect(() => {
    const allChecked =
      Object.values(checkedItems).every(Boolean) &&
      Object.keys(serviceImportListOrigin).length > 0;
    setIsAllChecked(allChecked);
  }, [checkedItems]);
  const handleSelectAll = () => {
    const newCheckedItems = {};
    const newCheckedGroups = {};

    Object.keys(serviceImportList).forEach((platform) => {
      const services = serviceImportList[platform].filter(
        (service) => !service.isServiceAlreadyExists
      );

      services.forEach((service) => {
        newCheckedItems[service.service] = !isAllChecked;
      });
      newCheckedGroups[platform] = services.length > 0 && !isAllChecked;
    });

    setCheckedItems(newCheckedItems);
    setCheckedGroups(newCheckedGroups);
    setIsAllChecked(!isAllChecked);
  };
  const handleGroupSelect = (platform) => {
    const isGroupChecked = !checkedGroups[platform];
    const newCheckedItems = { ...checkedItems };
    const newCheckedGroups = { ...checkedGroups };
    serviceImportList[platform]
      .filter((service) => !service.isServiceAlreadyExists)
      .forEach((row) => {
        newCheckedItems[row.service] = isGroupChecked;
      });
    newCheckedGroups[platform] = isGroupChecked;

    setCheckedItems(newCheckedItems);
    setCheckedGroups(newCheckedGroups);
    const areAllGroupsChecked = Object.values(newCheckedGroups).every(Boolean);
    setIsAllChecked(areAllGroupsChecked);
  };
  const handleItemSelect = (itemId, platform) => {
    const newCheckedItems = {
      ...checkedItems,
      [itemId]: !checkedItems[itemId],
    };
    setCheckedItems(newCheckedItems);
    const isPlatformAllChecked = serviceImportList[platform]
      .filter((row) => !row.isServiceAlreadyExists)
      .every((row) => newCheckedItems[row.service]);

    setCheckedGroups((prev) => ({ ...prev, [platform]: isPlatformAllChecked }));
    const areAllItemsChecked = Object.keys(serviceImportList).every((plat) =>
      serviceImportList[plat]
        .filter((row) => !row.isServiceAlreadyExists)
        .every((row) => newCheckedItems[row.service])
    );

    setIsAllChecked(areAllItemsChecked);
  };

  const handleTogglePlatform = (platform) => {
    setExpandedPlatforms((prev) => ({
      ...prev,
      [platform]: !prev[platform],
    }));
  };

  const handleSelectProvider = (provider) => {
    setStatusSelect((prev) => ({
      ...prev,
      provider,
    }));
  };

  const handleSelectClassify = (classify) => {
    setStatusSelect((prev) => ({
      ...prev,
      classify,
    }));
  };

  const handleGetListService = () => {
    if (!statusSelect?.provider) {
      return toast.error("Bạn chưa chọn nhà cung cấp!");
    }
    const data = {
      providerId: statusSelect?.provider?.id,
    };
    dispatch(ServiceImportActions.getAllServiceByProviderId({ data }));
    setExpandedPlatforms({});
  };

  const handlePriceChange = (serviceId, vipLevel, percentage) => {
    const validatedPercentage = Math.min(
      Math.max(parseFloat(percentage), 0),
      5000
    );
    const basePrice =
      parseFloat(
        Object.values(serviceImportList)
          .flat()
          .find((row) => row?.service === serviceId)?.price
      ) || 0;
    const calculatedPrice = Math.ceil(
      basePrice * (1 + validatedPercentage / 100)
    );

    setVipPrices((prevPrices) => ({
      ...prevPrices,
      [serviceId]: {
        ...prevPrices[serviceId],
        [vipLevel]: { percentage: validatedPercentage, price: calculatedPrice },
      },
    }));
  };

  const handleSelectedServices = () => {
    return Object.keys(checkedItems).filter((itemId) => checkedItems[itemId]);
  };
  async function handleCreateServicesProvider(selectedServices) {
    for (const service of selectedServices) {
      await new Promise((resolve) => {
        dispatch(
          ServiceImportActions?.createServiceFromProvider({
            data: service,
            onSuccess: () => {
              const data = {
                providerId: statusSelect?.provider?.id,
              };
              setCheckedItems({});
              dispatch(ServiceImportActions.setServiceImportList({}));
              dispatch(
                ServiceImportActions.getAllServiceByProviderId({ data })
              );
              resolve();
            },
          })
        );
      });
    }
  }
  const handleImport = () => {
    const selectedServiceIds = handleSelectedServices();
    if (selectedServiceIds.length == 0) {
      return toast.error("Vui lòng chọn ít nhất một dịch vụ!");
    }
    if (
      Object.values(statusSelect?.provider).some(
        (value) => value === null || value === undefined || value === ""
      )
    ) {
      return toast.error("Vui lòng chọn nhà cung cấp!");
    }
    if (!statusSelect?.classify) {
      return toast.error("Vui lòng chọn phân loại!");
    }
    const selectedServices = [];
    Object.keys(serviceImportListOrigin).forEach((platform) => {
      serviceImportListOrigin[platform.toLowerCase()].forEach((service) => {
        if (selectedServiceIds.includes(service.service.toString())) {
          selectedServices.push(service);
        }
      });
    });
    const payloads = selectedServices.map((serviceDetails) => {
      return {
        vipPrices: [
          {
            level: 0,
            increasePercentage:
              vipPrices[serviceDetails?.service]?.[0]?.percentage || 30,
          },
          {
            level: 1,
            increasePercentage:
              vipPrices[serviceDetails?.service]?.[1]?.percentage || 25,
          },
          {
            level: 2,
            increasePercentage:
              vipPrices[serviceDetails?.service]?.[2]?.percentage || 20,
          },
          {
            level: 3,
            increasePercentage:
              vipPrices[serviceDetails?.service]?.[3]?.percentage || 15,
          },
        ],
        status: true,
        isBestSellers: false,
        name: {
          en: `${serviceDetails?.service} | ${serviceDetails?.name}`,
        },
        code: "sv1",
        description: {
          en: "",
        },
        special: "",
        orderSuccessDescription: "",
        linkDescription: {},
        scriptGroupCode: serviceDetails?.platformGroup,
        scriptCode: statusSelect?.provider?.name.concat([
          "_",
          serviceDetails?.service,
        ]),
        serviceGroupID: statusSelect?.classify,
        serviceTags: [],
        partnerCode: statusSelect?.provider?.name,
        partnerServiceID: serviceDetails?.service,
        unit: "",
        cost: "",
        originPrice: serviceDetails?.price,
        type: "runService",
        attributes: [
          {
            label: "Link",
            code: "link",
            dataType: "text",
            description: "",
            special: "",
            required: true,
            errorMessage: {
              label: "",
              code: "",
            },
            commentType: false,
            options: [],
          },
        ],
        minValue: serviceDetails?.min,
        maxValue: serviceDetails?.max,
        customPrice: [],
        listLocalServer: [],
      };
    });
    handleCreateServicesProvider(payloads);
  };

  return (
    <MST.Container title="Import dịch vụ">
      <div className="flex flex-col items-center gap-3 md:flex-row">
        <ProviderSelect
          value={statusSelect?.provider}
          onChange={handleSelectProvider}
          label="Nhà cung cấp"
          placeholder={"Chọn nhà cung cấp"}
        />
        <MST.Button className="text-nowrap max-sm:w-full max-sm:text-center max-sm:flex max-sm:justify-between max-sm:items-center" onClick={handleGetListService}>
          <p className="w-full text-center">Danh sách</p>
        </MST.Button>
        <ClassifySelect
          value={statusSelect?.classify}
          onChange={handleSelectClassify}
          label="Phân loại"
          placeholder={"Chọn phân loại"}
        />
        <MST.Button onClick={handleImport} isLoading={isSubmitting} className="max-sm:w-full">
          {isSubmitting ? <CircularProgress /> : <p className="w-full text-center">Nhập</p>}
        </MST.Button>
      </div>
      <div className="flex items-center gap-1.5 md:gap-5">
        <div className="flex-grow">
          <SearchService
            disabled={Object.keys(serviceImportListOrigin).length <= 0}
          />
        </div>
        <div className="w-1/3 md:w-1/4">
          <FilterByPlatform
            disabled={Object.keys(serviceImportListOrigin).length <= 0}
            onChange={() => {}}
            label="Nền tảng"
            placeholder={"Chọn nền tảng"}
          />
        </div>
      </div>
      {isFetching ? (
        <div className="flex items-center justify-center pt-10">
          <CircularProgress />
        </div>
      ) : (
        <div className="overflow-auto border border-gray-200 rounded max-h-[90%]">
          <table className="min-w-full text-gray-700">
            <thead>
              <tr>
                <th className="p-0.5 py-2 w-8 text-center">
                  <input
                    type="checkbox"
                    checked={isAllChecked}
                    onChange={handleSelectAll}
                    className="border-gray-300 rounded"
                  />
                </th>
                <th className="p-0.5 py-2 w-10 text-left">ID</th>
                <th className="p-0.5 py-2 w-48 max-w-48 text-wrap text-left">
                  Service
                </th>
                <th className="p-0.5 py-2 w-32 text-center">Giá</th>
                <th className="p-0.5 py-2 w-32 text-left">VIP 0</th>
                <th className="p-0.5 py-2 w-32 text-left">VIP 1</th>
                <th className="p-0.5 py-2 w-32 text-left">VIP 2</th>
                <th className="p-0.5 py-2 w-32 text-left">VIP 3</th>
              </tr>
            </thead>
            <tbody className="">
              {Object.keys(serviceImportList).length > 0 &&
                Object.keys(serviceImportList)?.map((platform) => (
                  <>
                    <tr className="bg-gray-200 " key={platform}>
                      <td className="p-0.5 py-2 w-8 text-center">
                        <input
                          type="checkbox"
                          checked={checkedGroups[platform]}
                          onChange={() => handleGroupSelect(platform)}
                        />
                      </td>
                      <td className="p-0.5 py-2 w-8 text-center">
                        <button onClick={() => handleTogglePlatform(platform)}>
                          {expandedPlatforms[platform] ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m19.5 8.25-7.5 7.5-7.5-7.5"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m8.25 4.5 7.5 7.5-7.5 7.5"
                              />
                            </svg>
                          )}
                        </button>
                      </td>
                      <td
                        colSpan={7}
                        className="p-0.5 py-2 pl-10 text-left capitalize text-base font-semibold text-orange-400"
                      >
                        {platform} ({serviceImportList[platform]?.length})
                      </td>
                    </tr>
                    {expandedPlatforms[platform] &&
                      serviceImportList[platform]?.map((service) => (
                        <tr
                          key={service?.service}
                          className={`border-t border-gray-200 group  ${
                            service?.isServiceAlreadyExists
                              ? "opacity-20 [&>td:opacity-20] cursor-not-allowed"
                              : ""
                          }`}
                        >
                          <td className="p-0.5 py-2 w-8 text-center ">
                            {service?.isServiceAlreadyExists ? (
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="hidden w-6 h-6 m-auto text-red-500 group-hover:block"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                  />
                                </svg>
                              </div>
                            ) : (
                              <input
                                type="checkbox"
                                disabled={service?.isServiceAlreadyExists}
                                checked={checkedItems[service.service]}
                                onChange={() =>
                                  handleItemSelect(service.service, platform)
                                }
                                className="border-gray-300 rounded cursor-pointer"
                              />
                            )}
                          </td>
                          <td className="p-0.5 py-2 w-10 text-left truncate">
                            {service?.service}
                          </td>
                          <td className="p-0.5 py-2 w-48 max-w-48 text-wrap text-left">
                            {service?.name}
                          </td>
                          <td className="p-0.5 py-2 w-32 text-center">
                            {service?.price?.toLocaleString("vi", {
                              style: "currency",
                              currency: "VND",
                            })}
                          </td>
                          {[0, 1, 2, 3].map((level) => (
                            <td
                              key={level}
                              className="p-0.5 py-2 w-32 text-center"
                            >
                              {checkedItems[service.service] ? (
                                <div className="flex items-center">
                                  <div className="flex items-center justify-center w-20 px-2 py-1 text-white bg-gray-700 border border-gray-500 rounded">
                                    {vipPrices[service?.service]?.[
                                      level
                                    ]?.price?.toLocaleString("vi", {
                                      style: "currency",
                                      currency: "VND",
                                    }) || "0đ"}
                                  </div>
                                  <div className="flex items-center bg-gray-700 border border-gray-500 rounded">
                                    <input
                                      type="number"
                                      value={
                                        vipPrices[service?.service]?.[level]
                                          ?.percentage || ""
                                      }
                                      onChange={(e) =>
                                        handlePriceChange(
                                          service?.service,
                                          level,
                                          e.target.value
                                        )
                                      }
                                      min={0}
                                      step={0.1}
                                      className="w-16 px-2 py-1 text-center text-white bg-gray-700 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                                    />
                                    <span className="px-2 text-white">%</span>
                                  </div>
                                </div>
                              ) : (
                                <div className="w-full opacity-50 h-9">-</div>
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                  </>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </MST.Container>
  );
};

export default ServiceImport;
