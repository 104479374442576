import React, { useState } from "react";
import MST from "../../components";
import PlugIcon from "../../images/icons/PlugIcon";
import ContentPromptList from "./ContentPromptList";
import Modal from "../../components/base/modal/Modal";
import ContentPromptAction from "./ContentPromptAction";
import { useDispatch, useSelector } from "react-redux";
import { ContentPromptActions } from "../../app/services/content-prompt/content-prompt.slice";

const ContentPromptPage = () => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    topic: "",
    keywords: [],
  });
  const [input, setInput] = useState({
    topic: "",
    keywords: [""],
  });
  const { loading } = useSelector((state) => state.contentPrompt);

  const validate = () => {
    let isValid = true;
    const newErrorMessage = {
      topic: "",
      keywords: [],
    };

    if (!input.topic.trim()) {
      newErrorMessage.topic = "Chủ đề không được để trống";
      isValid = false;
    }

    input.keywords.forEach((keyword, index) => {
      if (!keyword.trim()) {
        newErrorMessage.keywords[index] = "Từ khóa không được để trống";
        isValid = false;
      } else {
        newErrorMessage.keywords[index] = "";
      }
    });

    setErrorMessage(newErrorMessage);
    return isValid;
  };

  const handleInputChange = (field, value, index) => {
    if (field === "keywords") {
      const newKeywords = [...input.keywords];
      newKeywords[index] = value;
      setInput((prev) => ({ ...prev, keywords: newKeywords }));
      setErrorMessage((prev) => {
        const newKeywordErrors = [...prev.keywords];
        newKeywordErrors[index] = "";
        return { ...prev, keywords: newKeywordErrors };
      });
    } else {
      setInput((prev) => ({ ...prev, [field]: value }));
      setErrorMessage((prev) => ({ ...prev, [field]: "" }));
    }
  };

  const addKeyword = () => {
    setInput((prev) => ({ ...prev, keywords: [...prev.keywords, ""] }));
    setErrorMessage((prev) => ({ ...prev, keywords: [...prev.keywords, ""] }));
  };

  const removeKeyword = (index) => {
    setInput((prev) => ({
      ...prev,
      keywords: prev.keywords.filter((_, i) => i !== index),
    }));
    setErrorMessage((prev) => ({
      ...prev,
      keywords: prev.keywords.filter((_, i) => i !== index),
    }));
  };

  const handleClose = () => {
    setIsShow(false);
    setInput((prev) => ({
      ...prev,
      topic: "",
      keywords: [""],
    }));
    setErrorMessage((prev) => ({
      ...prev,
      topic: "",
      keywords: [],
    }));
  };

  const handleSubmit = () => {
    if (validate()) {
      dispatch(
        ContentPromptActions.createContentPrompt({
          input,
          onSuccess: () => {
            handleClose();
            dispatch(ContentPromptActions.getAllContentPrompt());
          },
        })
      );
    }
  };

  const handlePost = () => {
    if (validate()) {
      dispatch(
        ContentPromptActions.postNewsByContentPrompt({
          input,
          onSuccess: () => {
            handleClose();
          },
        })
      );
    }
  };

  return (
    <MST.Container
      title="Danh sách chủ đề"
      right={
        <div>
          <MST.Button
            onClick={() => setIsShow(true)}
            className="max-sm:w-full"
          >
            <div className="flex items-center justify-center w-full">
            <div className="service-icon-create">
                <PlugIcon />
              </div>
              <p>Tạo chủ đề mới</p></div>
          </MST.Button>
        </div>
      }
    >
      <Modal
        contentWidth={600}
        isShow={isShow}
        onHide={() => {
          handleClose();
        }}
        content={
          <ContentPromptAction
            input={input}
            errorMessage={errorMessage}
            handleInputChange={handleInputChange}
            addKeyword={addKeyword}
            removeKeyword={removeKeyword}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            isLoading={loading}
            isUpdate={false}
            handlePost={handlePost}
          />
        }
      />
      <div className="md:p-6">
        <ContentPromptList />
      </div>
    </MST.Container>
  );
};

export default ContentPromptPage;
