import React from "react";
import MST from "../../components";
import SearchIcon from "../../images/icons/SearchIcon";
import { useDispatch, useSelector } from "react-redux";

import {
  ContentPromptActions,
  ContentPromptSelectors,
} from "../../app/services/content-prompt/content-prompt.slice";

const ContentPromptSearch = () => {
  const dispatch = useDispatch();

  const keySearch = useSelector(ContentPromptSelectors.keySearch);
  return (
    <div className="search-service-container">
      <MST.Input
        value={keySearch}
        onChange={(e) => {
          dispatch(ContentPromptActions.setKeySearch(e.target.value));
        }}
        placeholder={"Nhập chủ đề"}
        right={
          <div className="w-16 service-search-btn">
            <SearchIcon />
          </div>
        }
      />
    </div>
  );
};

export default ContentPromptSearch;
