import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CustomerActions,
  CustomerSelectors,
} from "../../app/services/customer/customer.slice";

const CustomerFilter = () => {
  const filterStateRedux = useSelector(CustomerSelectors.filter);
  const dispatch = useDispatch();
  const handleChange = (event) => {
    const payload = JSON.parse(event.target.value);
    dispatch(CustomerActions.setFilter(payload));
  };
  return (
    <div className="w-full md:max-w-40">
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Lọc</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={JSON.stringify(filterStateRedux) || ""}
          label="Filter"
          onChange={handleChange}
        >
          <MenuItem value={JSON.stringify({ sortBalance: "asc" })}>
            Số dư tăng dần
          </MenuItem>
          <MenuItem value={JSON.stringify({ sortBalance: "desc" })}>
            Số dư giảm dần
          </MenuItem>
          <MenuItem value={JSON.stringify({ hasUsedApi: true })}>
            Đã kết nối API
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default CustomerFilter;
