import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";
import Select from "../../components/base/select/Select";
import {
  DashBoardActions,
  DashBoardSelectors,
} from "../../app/services/dashboard/dashboard.slice";
import { capitalizeFirstLetter } from "../../app/function";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default function DashBoarLeftChartPie() {
  const dispatch = useDispatch();
  const dataPie = useSelector(DashBoardSelectors.pie);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const DATA_PICK = [
    { name: "Tổng đơn hàng", value: "order" },
    { name: "Tổng tiền", value: "money" },
    { name: "Tổng doanh thu", value: "revenue" },
  ];

  const [values, setValues] = useState(DATA_PICK[0]);

  const amountTypeSelected = DATA_PICK.find(
    (type) => type?.name === values?.name
  );

  const handleFetchTotal = (type) => {
    setIsLoading(true);
    dispatch(
      DashBoardActions.getDataPie({
        type: type.value,
        onSuccess: () => setIsLoading(false),
        onFail: () => setIsLoading(false),
      })
    );
  };

  useEffect(() => {
    handleFetchTotal(values);
  }, []);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  return (
    <div className="px-6 bg-white rounded-lg shadow-custom py-1.5">
      <div className="flex items-center justify-between">
        <h2 className="text-base font-semibold">Thống kê nền tảng</h2>
        <Select.Total
          canSearch={false}
          placeholder="Chọn tổng"
          data={DATA_PICK}
          selected={amountTypeSelected}
          setSelected={(type) => {
            setValues(type);
            handleFetchTotal(type);
          }}
        />
      </div>

      {isLoading ? (
        <div className="flex items-center justify-center h-64">
          <div className="loaderSmall"></div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center md:flex-row">
          <PieChart width={400} height={400}>
            <Pie
              data={dataPie?.chart || []}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={120}
              fill="#8884d8"
              dataKey="y"
              activeIndex={activeIndex}
              activeShape={{ r: 10 }}
              onMouseEnter={onPieEnter}
            >
              {dataPie?.chart?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={dataPie?.colors[index % dataPie?.colors.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend
              payload={dataPie?.chart?.map((item, index) => ({
                color: dataPie?.colors[index % dataPie?.colors.length],
                type: "square",
                value: capitalizeFirstLetter(item.x),
              }))}
              layout="vertical"
              verticalAlign="middle"
              align="left"
            />
          </PieChart>
        </div>
      )}
    </div>
  );
}
