import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import MST from "../../components";
import Tab from "../../components/tab/Tab";
import PlugIcon from "../../images/icons/PlugIcon";
// import ServiceList from "./ServiceList";

import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { ServiceActions } from "../../app/services/service/service.slice";
import NewsList from "./NewsList";
import { NewsActions, NewsSelectors } from "../../app/services/news/news.slice";

function NewsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const newsCategories = useSelector(NewsSelectors.newsCategories);

  // const
  useEffect(() => {
    return () => {
      dispatch(NewsActions.setNewsCategory(""));
    };
  }, []);

  useEffect(() => {
    dispatch(NewsActions.getNewsCategories(""));
  }, []);

  const tabList = [
    {
      name: "Tất cả",
      onClick: () => {
        dispatch(NewsActions.setNewsCategory(""));
      },
    },
  ];

  newsCategories?.map((category) => {
    return tabList.push({
      name: category.name,
      onClick: () => {
        dispatch(NewsActions.setNewsCategory(category.value));
      },
    });
  });

  const contentList = [<NewsList></NewsList>];
  newsCategories.map(() => {
    return contentList.push(<NewsList></NewsList>);
  });

  const onCreate = () => navigate("/services/news/create");

  return (
    <MST.Container
      title="Danh sách tin"
      right={
        <div>
          <MST.Button onClick={onCreate} className="flex items-center md:justify-between max-sm:w-full">
            <div className="flex items-center justify-center w-full">
              <div className="service-icon-create">
                <PlugIcon />
              </div>
              <p className="text-center">Tạo mới tin tức</p>
            </div>
          </MST.Button>
        </div>
      }
    >
      <div className="service-content">
        <Tab list={tabList} contentList={contentList} />
      </div>
    </MST.Container>
  );
}

export default NewsPage;
