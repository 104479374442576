import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useResolvedPath } from "react-router-dom";
import IconMenu from "./IconMenu";
import OneMenu from "./OneMenu";
import CustomersIcon from "./icons/CustomersIcon";
import SVGDashBoard from "./icons/DashBoardIcon";
import KeywordIcon from "./icons/KeywordIcon";
import NewsIcon from "./icons/NewsIcon";
import NotificationIcon from "./icons/NotificationIcon";
import OrdersIcon from "./icons/OrdersIcon";
import PromotionIcon from "./icons/PromotionIcon";
import ServicesIcon from "./icons/ServicesIcon";
import SettingsIcon from "./icons/SettingsIcon";
import VoucherIcon from "./icons/VoucherIcon";
import "./style.css";
import { useContainerDimensions } from "../../app/hooks";
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from "@heroicons/react/24/solid";
export const adminMenu = {
  services: [
    {
      name: "Thống kê",
      icon: <SVGDashBoard />,
      children: [
        {
          name: "Bảng thống kê",
          path: "/services/dashboard",
        },
        {
          name: "Lượt truy cập",
          path: "/services/visit",
        },
      ],
    },
    {
      name: "Dịch vụ",
      icon: <ServicesIcon />,
      children: [
        {
          name: "Danh sách dịch vụ",
          path: "/services/services",
        },
        {
          name: "Danh sách nhóm dịch vụ",
          path: "/services/services-groups",
        },
        {
          name: "Bảng giá dịch vụ",
          path: "/services/services-prices",
        },
        {
          name: "Nhà cung cấp",
          path: "/services/supplier",
        },
      ],
    },
    {
      name: "Đơn hàng",
      icon: <OrdersIcon />,
      children: [
        {
          name: "Danh sách đơn hàng",
          path: "/services/orders",
        },
      ],
    },
    {
      name: "Khách hàng",
      icon: <CustomersIcon />,
      children: [
        {
          name: "Danh sách khách hàng",
          path: "/services/customers",
        },
      ],
    },

    {
      name: "Vouchers",
      icon: <VoucherIcon />,
      children: [
        {
          name: "Danh sách voucher",
          path: "/services/vouchers",
        },
        {
          name: "Lịch sử áp dụng voucher",
          path: "/services/voucher-histories",
        },
      ],
    },
    {
      name: "Tin tức",
      icon: <NewsIcon />,
      children: [
        {
          name: "Danh sách tin tức",
          path: "/services/news/",
        },
        {
          name: "Chủ đề và từ khoá",
          path: "/services/content-prompt",
        },
      ],
    },
    {
      name: "Thông báo",
      icon: <NotificationIcon />,
      children: [
        {
          name: "Danh sách thông báo",
          path: "/services/notifications",
        },
      ],
    },
    {
      name: "Promotions",
      icon: <PromotionIcon />,
      children: [
        {
          name: "Danh sách khuyến mại",
          path: "/services/promotions",
        },
      ],
    },
    {
      name: "Cài đặt",
      icon: <SettingsIcon />,
      children: [
        {
          name: "API KEY",
          path: "/services/settings/api-key",
        },
        {
          name: "Support account",
          path: "/services/settings/support-account",
        },
        {
          name: "News Account",
          path: "/services/settings/news-account",
        },
        {
          name: "Local server",
          path: "/services/settings/local-server",
        },
        {
          name: "Thanh toán",
          path: "/services/settings/payment",
        },
        {
          name: "Cài đặt chung",
          path: "/services/settings/general",
        },
        {
          name: "Đối tác",
          path: "/services/settings/partner",
        },
      ],
    },
  ],
  keyword: [
    {
      name: "Thống kê",
      icon: <SVGDashBoard />,
      children: [
        {
          name: "Bảng thống kê",
          path: "/keyword/dashboard",
        },
      ],
    },
    {
      name: "Từ khóa",
      icon: <KeywordIcon />,
      children: [
        {
          name: "Quản lý từ khóa",
          path: "/keyword/keywords",
        },
        {
          name: "Quản lý chủ đề",
          path: "/keyword/topics",
        },
      ],
    },
    {
      name: "Cài đặt",
      icon: <SettingsIcon />,
      children: [
        {
          name: "Thanh toán",
          path: "/keyword/settings/payment",
        },
        {
          name: "Cài đặt chung",
          path: "/keyword/settings/general",
        },
        {
          name: "Đối tác",
          path: "/keyword/settings/partner",
        },
      ],
    },
  ],
};
function MainMenu() {
  const navigate = useNavigate();
  const { pathname } = useResolvedPath();
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState("services");
  const [currentSubTab, setCurrentSubTab] = useState("services");
  const [currentSubSubTab, setCurrentSubSubTab] = useState("services");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const userInfo = useSelector((state) => state.login.userInfo);
  const isRoleSupport = userInfo?.role === "support";
  const isNewsAccount = userInfo?.role === "newsRole";

  const supportMenu = {
    services: [
      {
        name: "Đơn hàng",
        icon: <OrdersIcon />,
        children: [
          {
            name: "Danh sách đơn hàng",
            path: "/services/orders",
          },
        ],
      },
      {
        name: "Vouchers",
        icon: <VoucherIcon />,
        children: [
          {
            name: "Danh sách voucher",
            path: "/services/vouchers",
          },
          {
            name: "Lịch sử áp dụng voucher",
            path: "/services/voucher-histories",
          },
        ],
      },
      {
        name: "Tin tức",
        icon: <NewsIcon />,
        children: [
          {
            name: "Danh sách tin tức",
            path: "/services/news/",
          },
          {
            name: "Chủ đề và từ khoá",
            path: "/services/content-prompt",
          },
        ],
      },
      {
        name: "Khách hàng",
        icon: <CustomersIcon />,
        children: [
          {
            name: "Danh sách khách hàng",
            path: "/services/customers",
          },
        ],
      },
      {
        name: "Thông báo",
        icon: <NotificationIcon />,
        children: [
          {
            name: "Danh sách thông báo",
            path: "/services/notifications",
          },
        ],
      },
      {
        name: "Promotions",
        icon: <PromotionIcon />,
        children: [
          {
            name: "Danh sách khuyến mại",
            path: "/services/promotions",
          },
        ],
      },
    ],
  };
  const newsMenu = {
    services: [
      {
        name: "Tin tức",
        icon: <NewsIcon />,
        children: [
          {
            name: "Danh sách tin tức",
            path: "/services/news/",
          },
          {
            name: "Chủ đề và tự khoá",
            path: "/services/content-prompt",
          },
        ],
      },
    ],
  };

  // const menuList = isRoleSupport ? supportMenu : adminMenu
  // const menuList = isNewsAccount
  //   ? newsMenu
  //   : isRoleSupport
  //   ? supportMenu
  //   : adminMenu;
  const getMatchingMenuItems = useCallback(
    (menu, mappings) => {
      const services = menu.services
        .map((menuItem) => {
          const matchedChildren = menuItem.children.filter((child) =>
            mappings.some((mapping) => mapping === child.path)
          );
          if (matchedChildren.length > 0) {
            return {
              ...menuItem,
              children: matchedChildren,
            };
          }
          return null;
        })
        .filter(Boolean);
      return {
        services,
        keyword: adminMenu.keyword,
      };
    },
    [userInfo]
  );

  const menuList =
    isRoleSupport || isNewsAccount
      ? getMatchingMenuItems(adminMenu, userInfo?.allowedPages || [])
      : adminMenu;
  useEffect(() => {
    setIsMenuOpen(false);
    const tempParams = pathname.split("/").filter((x) => !_.isEmpty(x));
    if (tempParams[0]) {
      setCurrentTab(tempParams[0]);
    }

    if (tempParams[1]) {
      setCurrentSubTab(tempParams[1]);
    }
    if (tempParams[2]) {
      setCurrentSubSubTab(tempParams[2]);
    } else {
      setCurrentSubSubTab("");
    }
  }, [pathname]);
  // Đóng menu khi click ra ngoài
  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const { width, height } = useContainerDimensions(window);
  const handleMenuToggle = () => {
    setIsMenuOpen((prev) => !prev);
  };
  return (
    <div className="relative inset-y-0 h-full">
       <div
        className={` md:hidden absolute z-50  flex items-center max-sm:bg-[#ffaa47cf] justify-center w-12 h-7 text-white -translate-y-1/2  cursor-pointer  top-1/2  ${isMenuOpen ? 'rounded-s-full left-[201px]' : 'rounded-e-full -right-9'}`}
        onClick={handleMenuToggle}
      >
        {isMenuOpen ? <ChevronDoubleLeftIcon className="block w-5 md:hidden" /> : <ChevronDoubleRightIcon className="block w-5 md:hidden" />}
      </div>
      <div
        className={`menu-container h-full rounded-e-3xl ${isMenuOpen ? "open" : ""}`}
        ref={menuRef}
      >
        <div className="menu-content">
          <div className="menu-list-container">
            <div className="menu-btn-conatiner">
              <div
                role="button"
                className={`menu-btn${
                  currentTab === "services" ? "-active" : ""
                }`}
                onClick={() => {
                  !isRoleSupport && navigate("/services/dashboard");
                }}
              >
                <div className="menu-btn-label">Dịch vụ</div>
              </div>
              <div
                role="button"
                className={`menu-btn${
                  currentTab === "keyword" ? "-active" : ""
                }`}
                onClick={() => {
                  !isRoleSupport && navigate("/keyword/dashboard");
                }}
              >
                <div className="menu-btn-label">Keyword Tool</div>
              </div>
            </div>
            {(menuList[`${currentTab}`] || []).length > 0 ? (
              <div className="menu-list-item-container">
                {(menuList[`${currentTab}`] || []).map((oneItem) => {
                  return (
                    <OneMenu
                      currentSubTab={currentSubTab}
                      currentSubSubTab={currentSubSubTab}
                      key={oneItem.name}
                      item={oneItem}
                    />
                  );
                })}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainMenu;
