import React from "react";
import { useNavigate } from "react-router";
import MST from "../../components";
import PlugIcon from "../../images/icons/PlugIcon";
import NotificationList from "./NotificationList";
import "./style.css";
import { NotificationActions } from "../../app/services/notification/notification.slice";
import { useDispatch } from "react-redux";

function NotificationPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onCreate = () => {
    dispatch(NotificationActions.setNotificationDetail(null));
    navigate("/services/notifications/create");
  };

  return (
    <MST.Container
      title="Danh sách thông báo"
      right={
        <div>
          <MST.Button
            onClick={onCreate}
            className="max-sm:w-full"
          >
            <div className="flex items-center justify-center w-full">
            <div className="service-icon-create">
                <PlugIcon />
              </div>
              <p>Tạo mới thông báo</p></div>
          </MST.Button>
        </div>
      }
    >
      <div className="md:p-6">
        <NotificationList />
      </div>
    </MST.Container>
  );
}

export default NotificationPage;
