import { useDispatch, useSelector } from "react-redux";
import { SupplieActions, SupplieSelectors } from "../../app/services/supplies/supplies.slice.js";
import MST from "../../components/index.js";
import Table from "../../components/base/table/Table.js";
import PlugIcon from "../../images/icons/PlugIcon.js";
import { useEffect, useState, useMemo } from "react";
import { formatPriceVND } from "../../app/utils/format.js";
import SupplieActionsModal from "./components/Supplie.Options.js";
import FormSupplie from "./components/FormSupplie.js";

const SuppliePage = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const isFetching = useSelector(SupplieSelectors.isFetching);
  const supplieList = useSelector(SupplieSelectors.supplieList);

  useEffect(() => {
    dispatch(SupplieActions.getAllSupplie());
  }, [dispatch]);

  const handleCloseModal = () => setIsOpenModal(false);
  const handleCreateSupplie = () => {
    dispatch(SupplieActions.setSupplieDetail(null));
    setIsOpenModal(true);}
  const handleUpdateSupplie = (id) => {
    dispatch(SupplieActions.getSupplieById(id));
    setIsOpenModal(true);
  };

  const thead = useMemo(() => [
    { name: "STT", style: { width: 20 } },
    { name: "Provider" },
    { name: "Balance" },
    { name: "Thao tác", style: { width: 100 } },
  ], []);

  const genDataTable = useMemo(() => (
    supplieList?.map((x, index) => [
      { value: index + 1 },
      { value: x?.name || "Nguyễn Huy Tới" },
      { value: `${formatPriceVND(x.balanceAlert)}đ` },
      { value: <SupplieActionsModal id={x._id} onOpenModalUpdate={handleUpdateSupplie} /> },
    ]) || []
  ), [supplieList]);

  return (
    <MST.Container
      title="Danh sách nhà cung cấp"
      right={
        <div className="flex items-center gap-3">
          <MST.Button className="max-sm:w-full max-sm:flex max-sm:items-center max-sm:justify-center" onClick={handleCreateSupplie} >
          <div className="service-icon-create"><PlugIcon /></div>
            <p>Thêm nhà cung cấp</p>
          </MST.Button>
        </div>
      }
    >
      {isFetching ? (
        <div className="w-8 h-8 pt-5 m-auto border-2 border-gray-300 rounded-full animate-spin border-t-blue-500" />
      ) : (
        <Table head={thead} body={genDataTable} />
      )}
      <FormSupplie error={""} isOpen={isOpenModal} onClose={handleCloseModal} />
    </MST.Container>
  );
};

export default SuppliePage;
