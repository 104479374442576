import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SupplieActions,
  SupplieSelectors,
} from "../../app/services/supplies/supplies.slice";

const SupplierStatistics = () => {
  const dispatch = useDispatch();
  const supplierStatistics = useSelector(SupplieSelectors.supplierStatistics);
  useEffect(() => {
    dispatch(SupplieActions.getSupplierStatistics());
  }, []);
  return (
    <div className="p-4 mx-auto ">
      <h1 className="mb-4 text-lg font-bold">Thống kê Nhà Cung Cấp</h1>
      <div className="overflow-x-auto border border-gray-300 rounded-md">
        <table className="w-full border-collapse table-auto">
          <thead className="text-sm">
            <tr className="bg-gray-50">
              <th className="px-2 py-1 text-left border border-gray-300">
                Tên nhà cung cấp
              </th>
              <th className="px-2 py-1 text-center border border-gray-300">
                Số dư hiện tại
              </th>
              <th className="px-2 py-1 text-center border border-gray-300">
                Tổng đơn hàng trong tháng
              </th>
              <th className="px-2 py-1 text-center border border-gray-300">
                Tổng chi tiêu trong tháng
              </th>
              <th className="px-2 py-1 text-center border border-gray-300">
                Tổng đơn hàng
              </th>
              <th className="px-2 py-1 text-center border border-gray-300">
                Tổng chi tiêu
              </th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {Object.keys(supplierStatistics)?.map((key) => {
              const supplier = supplierStatistics?.[key];
              return (
                <tr key={key} className="hover:bg-gray-100">
                  <td className="px-2 py-1 border border-gray-300">
                    {supplier?.name}
                  </td>
                  <td className="px-2 py-1 text-right border border-gray-300">
                    {new Intl.NumberFormat("vi-VN", {
                      style: "currency",
                      currency: "VND",
                    }).format(supplier?.balance)}
                  </td>
                  <td className="px-2 py-1 text-right border border-gray-300">
                    {supplier?.totalOrdersThisMonth}
                  </td>
                  <td className="px-2 py-1 text-right border border-gray-300">
                    {new Intl.NumberFormat("vi-VN", {
                      style: "currency",
                      currency: "VND",
                    }).format(supplier?.totalPriceThisMonth)}
                  </td>
                  <td className="px-2 py-1 text-right border border-gray-300">
                    {supplier?.totalOrders}
                  </td>
                  <td className="px-2 py-1 text-right border border-gray-300">
                    {new Intl.NumberFormat("vi-VN", {
                      style: "currency",
                      currency: "VND",
                    }).format(supplier?.totalPrice)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SupplierStatistics;
