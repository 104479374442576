import { useEffect, useRef, useState } from "react";

const useContainerDimensions = (window) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const getDimensions = () => ({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (window) {
      setDimensions(getDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window]);

  return dimensions;
};

// Use to useEffect hook not run on initial render
const useFirstRender = () => {
  const firstRender = useRef(true);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  return firstRender.current;
};

function useResponsiveWidth(contentWidthProp) {
  const [width, setWidth] = useState(window.innerWidth);
  const [contentWidth, setContentWidth] = useState(contentWidthProp);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Cập nhật lại width khi component được mount lần đầu tiên
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Điều chỉnh giá trị contentWidth dựa vào kích thước màn hình
  if (width < 768) {
    return { contentWidth: "100%", width: "100%" }; // màn hình nhỏ
  } else if (width < 1024) {
    return { contentWidth: "80%", width: "80%" }; // màn hình md
  } else {
    return { contentWidth: contentWidthProp, width: contentWidthProp }; // màn hình laptop trở lên, sử dụng giá trị truyền vào
  }
}
export { useContainerDimensions, useFirstRender,useResponsiveWidth };
