import React, { useState } from "react";
import MST from "../../components";
import PlugIcon from "../../images/icons/PlugIcon";
import ApiKeyList from "./ApiKeyList";
import Modal from "../../components/base/modal/Modal";
import ApiKeyAction from "./ApiKeyAction";
import { useDispatch, useSelector } from "react-redux";
import { ApiKeyActions } from "../../app/services/api-key/api-key.slice";
import ApiKeyImport from "./ApiKeyImport";

const ApiKeyPage = () => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [isShowImport, setIsShowImport] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    key: "",
    type: "",
  });
  const [input, setInput] = useState({
    key: "",
    type: "",
  });
  const { loading } = useSelector((state) => state.apiKey);

  const validate = () => {
    let isValid = true;
    const newErrorMessage = {
      key: "",
      type: "",
    };

    if (!input.key.trim()) {
      newErrorMessage.key = "Key không được để trống";
      isValid = false;
    }

    if (!input.type.trim()) {
      newErrorMessage.type = "Phân loại không được để trống";
      isValid = false;
    }

    setErrorMessage(newErrorMessage);
    return isValid;
  };

  const handleInputChange = (field, value) => {
    setInput((prev) => ({
      ...prev,
      [field]: value,
    }));
    setErrorMessage((prev) => ({
      ...prev,
      key: "",
      type: "",
    }));
  };

  const handleClose = () => {
    setIsShow(false);
    setInput((prev) => ({
      ...prev,
      key: "",
      type: "",
    }));
    setErrorMessage((prev) => ({
      ...prev,
      key: "",
      type: "",
    }));
  };

  const handleSubmit = () => {
    if (validate()) {
      dispatch(
        ApiKeyActions.createApiKey({
          input,
          onSuccess: () => {
            handleClose();
            dispatch(ApiKeyActions.getAllApiKey());
          },
        })
      );
    }
  };

  return (
    <MST.Container
      title="Danh sách API KEY"
      right={
        <div className="flex gap-2 ">
          <MST.Button
            onClick={() => setIsShowImport(true)}
            icon={
              <div className="service-icon-create">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 10.5v6m3-3H9m4.06-7.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
                  />
                </svg>
              </div>
            }
          >
            {"Import CSV"}
          </MST.Button>
          <MST.Button
            onClick={() => setIsShow(true)}
            icon={
              <div className="service-icon-create">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#ffffff"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            }
          >
            {"Tạo API KEY"}
          </MST.Button>
        </div>
      }
    >
      <ApiKeyImport
        {...{
          isShow: isShowImport,
          setIsShow: setIsShowImport,
        }}
      />
      <Modal
        contentWidth={600}
        isShow={isShow}
        onHide={() => {
          handleClose();
        }}
        content={
          <ApiKeyAction
            input={input}
            errorMessage={errorMessage}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            isLoading={loading}
            isUpdate={false}
          />
        }
      />
      <div className="md:p-6">
        <ApiKeyList />
      </div>
    </MST.Container>
  );
};

export default ApiKeyPage;
