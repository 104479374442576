import React from "react";
import "./style.css";
import MST from "../..";
import { useContainerDimensions } from "../../../app/hooks";

function Container({ children, title, right }) {
  const { width, height } = useContainerDimensions(window);

  return (
    <div className="cn-container p-1.5 md:p-6 max-sm:pt-3 md:mx-3  bg-white md:border md:border-blue-500 md:rounded-3xl h-full overflow-y-auto">
      {title ? (
        <div className={`flex flex-col items-center justify-between  gap-y-5 md:flex-row pb-3`}>
          <MST.Title>{title}</MST.Title>
          <div className={`flex-shrink-0 max-sm:w-full ${!right && 'hidden'}`}>{right}</div>
        </div>
      ) : (
        <></>
      )}
      <div
        className="cn-content "
        style={{
          // maxHeight: title ? height - 185 : height - 130,
          // overflow: "auto",
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default Container;
