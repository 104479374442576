import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ServicePricesActions } from "../../app/services/service-prices/serivce.slice";
import { useLocation, useNavigate } from "react-router-dom";
import MST from "../../components";
import Pagination from "../../components/base/pagination/Pagination";
import queryString from "query-string";
import ServicePricesSearchFilter from "./ServicePrices.SearchFilter";
import SearchIcon from "../../images/icons/SearchIcon";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExcelJS from "exceljs";

const ServicePricesPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    services,
    error,
    isLoading,
    pagination,
    dataServiceGroup,
    isLoadingServiceGroup,
    errorServiceGroup,
    exportData,
    isLoadingExportData,
    errorExportData,
  } = useSelector((state) => state.servicePrices);
  const [nameSearch, setNameSearch] = useState("");
  const [serviceGroupSearch, setServiceGroupSearch] = useState("");
  const [statusSearch, setStatusSearch] = useState("");
  const [isMounted, setIsMounted] = useState(false);

  const dispatch = useDispatch();

  const dataStatus = useMemo(
    () => [
      { value: "", label: "Tất cả" },
      { value: "true", label: "Đang hoạt động" },
      { value: "false", label: "Tạm ngưng" },
    ],
    []
  );

  useEffect(() => {
    const { name, serviceGroup, status } = queryString.parse(location.search);
    if (name !== nameSearch) {
      setNameSearch(name || "");
    }
    if (dataServiceGroup?.length == 0) {
      dispatch(ServicePricesActions.getDataServiceGroupRequest());
    }
    if (dataServiceGroup?.some((s) => s?.name == serviceGroup)) {
      setServiceGroupSearch(serviceGroup || "");
    }
    if (dataStatus.some((s) => s?.value == status)) {
      setStatusSearch(status || "");
    }
  }, []);

  useEffect(() => {
    dispatch(
      ServicePricesActions.getExportDataServicePricesRequest({
        pageSize: pagination.total,
        page: 1,
      })
    );
  }, [pagination.total]);

  useEffect(() => {
    dispatch(ServicePricesActions.resetServicePrices());
    dispatch(
      ServicePricesActions.getServicePricesRequest({
        name: nameSearch.length > 0 ? nameSearch : undefined,
        serviceGroup:
          serviceGroupSearch.length > 0 ? serviceGroupSearch : undefined,
        status:
          statusSearch.length > 0
            ? statusSearch == "true"
              ? true
              : false
            : undefined,
      })
    );
  }, [location.search]);

  const thead = [
    {
      name: "STT",
      style: { width: 40 },
    },
    {
      name: "Tên Dịch Vụ",
      style: {
        width: 200,
        textAlign: "center",
      },
    },
    {
      name: "Giá Khách Thường",
      style: {
        width: 100,
        textAlign: "center",
      },
    },
    {
      name: "Giá Người Bán",
      style: {
        width: 100,
        textAlign: "center",
      },
    },
    {
      name: "Giá Đại Lý",
      style: {
        width: 100,
        textAlign: "center",
      },
    },
    {
      name: "Giá Khách Đặc Biệt",
      style: {
        width: 100,
        textAlign: "center",
      },
    },
    {
      name: "Danh Mục",
      style: {
        width: 150,
        textAlign: "center",
      },
    },
    {
      name: "Trạng Thái",
      style: {
        width: 100,
        textAlign: "center",
      },
    },
  ];

  const genRenderList = useCallback((services, pagination) => {
    return services.map((x, index) => {
      const firstKey = Object.keys(x?.name)[0];
      const firstValue = x?.name[firstKey] || "";

      return [
        { value: (pagination.page - 1) * pagination.pageSize + index + 1 },
        {
          value: typeof x?.name === "object" ? firstValue : x?.name || "",
          style: {
            textAlign: "center",
          },
        },
        {
          value: Math.round(x.vipPrice0 * 100) / 100 + "đ" || "",
          style: {
            textAlign: "right",
          },
        },
        {
          value: Math.round(x.vipPrice1 * 100) / 100 + "đ" || "",
          style: {
            textAlign: "right",
          },
        },
        {
          value: Math.round(x.vipPrice2 * 100) / 100 + "đ" || "",
          style: {
            textAlign: "right",
          },
        },
        {
          value: Math.round(x.vipPrice3 * 100) / 100 + "đ" || "",
          style: {
            textAlign: "right",
          },
        },
        {
          value: x.serviceGroup,
          style: {
            textAlign: "center",
          },
        },
        {
          value: x.status ? "Đang hoạt động" : "Tạm ngưng",
          style: {
            textAlign: "center",
            color: x.status ? "green" : "red",
          },
        },
      ];
    });
  }, []);

  useEffect(() => {
    let queryString = "";
    if (nameSearch.length > 0) {
      queryString += `?name=${nameSearch}`;
    }
    if (serviceGroupSearch.length > 0) {
      if (queryString.length > 0) {
        queryString += `&serviceGroup=${serviceGroupSearch}`;
      } else {
        queryString += `?serviceGroup=${serviceGroupSearch}`;
      }
    }
    if (statusSearch.length > 0) {
      if (queryString.length > 0) {
        queryString += `&status=${statusSearch}`;
      } else {
        queryString += `?status=${statusSearch}`;
      }
    }
    navigate(`/services/services-prices${queryString}`);
    setIsMounted(true);
  }, [serviceGroupSearch, statusSearch]);

  useEffect(() => {
    if (!isMounted) {
      let queryString = "";
      if (nameSearch.length > 0) {
        queryString += `?name=${nameSearch}`;
      }
      if (serviceGroupSearch.length > 0) {
        if (queryString.length > 0) {
          queryString += `&serviceGroup=${serviceGroupSearch}`;
        } else {
          queryString += `?serviceGroup=${serviceGroupSearch}`;
        }
      }
      if (statusSearch.length > 0) {
        if (queryString.length > 0) {
          queryString += `&status=${statusSearch}`;
        } else {
          queryString += `?status=${statusSearch}`;
        }
      }
      navigate(`/services/services-prices${queryString}`);
      setIsMounted(true);
      return;
    }
    const timeOut = setTimeout(() => {
      let queryString = "";
      if (nameSearch.length > 0) {
        queryString += `?name=${nameSearch}`;
      }
      if (serviceGroupSearch.length > 0) {
        if (queryString.length > 0) {
          queryString += `&serviceGroup=${serviceGroupSearch}`;
        } else {
          queryString += `?serviceGroup=${serviceGroupSearch}`;
        }
      }
      if (statusSearch.length > 0) {
        if (queryString.length > 0) {
          queryString += `&status=${statusSearch}`;
        } else {
          queryString += `?status=${statusSearch}`;
        }
      }
      navigate(`/services/services-prices${queryString}`);
    }, 500);

    return () => {
      timeOut && clearTimeout(timeOut);
    };
  }, [nameSearch]);

  const [isExporting, setIsExporting] = useState(false);

  const handleExportExcel = () => {
    setIsExporting(true);
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Danh sách dịch vụ");

    sheet.columns = [
      {
        header: "STT",
        key: "index",
        width: 5,
      },
      {
        header: "Tên dịch vụ",
        key: "name",
        width: 60,
      },
      {
        header: "Giá khách thường",
        key: "vipPrice0",
        width: 25,
      },
      {
        header: "Giá người bán",
        key: "vipPrice1",
        width: 25,
      },
      {
        header: "Giá đại lý",
        key: "vipPrice2",
        width: 25,
      },
      {
        header: "Giá khách đặc biệt",
        key: "vipPrice3",
        width: 25,
      },
      {
        header: "Danh mục",
        key: "serviceGroup",
        width: 30,
      },
      {
        header: "Trạng thái",
        key: "status",
        width: 30,
      },
    ];

    exportData?.forEach((service, index) => {
      const firstKey = Object.keys(service?.name)[0];
      const firstValue = service?.name[firstKey] || "";
      sheet.addRow({
        index: index + 1,
        name:
          typeof service?.name === "object" ? firstValue : service?.name || "",
        vipPrice0: Math.round(service.vipPrice0 * 100) / 100 + "đ" || "",
        vipPrice1: Math.round(service.vipPrice1 * 100) / 100 + "đ" || "",
        vipPrice2: Math.round(service.vipPrice2 * 100) / 100 + "đ" || "",
        vipPrice3: Math.round(service.vipPrice3 * 100) / 100 + "đ" || "",
        serviceGroup: service.serviceGroup,
        status: service.status ? "Đang hoạt động" : "Tạm ngưng",
      });
    });

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "ServicePrices.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
      setIsExporting(false);
    });
  };

  return (
    <div>
      <MST.Container title="Bảng giá dịch vụ">
        <div className="my-5 px-2 flex flex-col md:flex-row justify-between items-center gap-2">
          <div className="flex-1 w-full">
            <MST.Input
              value={nameSearch}
              onChange={(e) => setNameSearch(e.target.value)}
              placeholder={"Nhập tên dịch vụ"}
              right={
                <div className="service-search-btn w-16">
                  <SearchIcon />
                </div>
              }
            />
          </div>
          <div className="w-full sm:!w-auto self-end flex flex-col sm:flex-row justify-end items-center gap-2">
            <div className="sm:!w-[210px] w-full">
              <ServicePricesSearchFilter
                value={serviceGroupSearch}
                options={[
                  {
                    label: "Tất cả",
                    value: "",
                  },
                  ...dataServiceGroup?.map(({ name }) => ({
                    label: name,
                    value: name,
                  })),
                ]}
                disabled={isLoadingServiceGroup}
                onChange={setServiceGroupSearch}
                label="Danh mục dịch vụ"
                placeholder={"Chọn danh mục dịch vụ"}
              />
            </div>
            <div className="w-full md:w-auto flex justify-center items-center gap-2">
              <div className="flex-1 sm:!w-[150px] w-full">
                <ServicePricesSearchFilter
                  value={statusSearch}
                  options={dataStatus}
                  onChange={setStatusSearch}
                  label="Trạng thái"
                  placeholder={"Chọn trạng thái"}
                />
              </div>
              <button
                disabled={isExporting || isLoadingExportData}
                onClick={handleExportExcel}
                className={`h-[50px] px-2 border border-[#FF8900] rounded-lg transition-all text-[#FF8900] hover:text-white hover:bg-[#FF8900] ${
                  isExporting || isLoadingExportData
                    ? "opacity-50"
                    : "opacity-100"
                }`}
              >
                <FileDownloadIcon /> Xuất Excel
              </button>
            </div>
          </div>
        </div>
        {isLoading ? (
          <p className="w-full select-none text-slate-500 text-center">
            <i>Loading...</i>
          </p>
        ) : (
          <div className="p-2">
            <div className="max-w-full overflow-x-auto">
              <MST.Table
                head={thead}
                body={genRenderList(services, pagination)}
              />
            </div>
            <div className="mt-5">
              <div className="service-pagination">
                <Pagination
                  {...pagination}
                  onChange={(page) => {
                    dispatch(
                      ServicePricesActions.getServicePricesRequest({
                        page: page,
                        name: nameSearch.length > 0 ? nameSearch : undefined,
                        serviceGroup:
                          serviceGroupSearch.length > 0
                            ? serviceGroupSearch
                            : undefined,
                        status:
                          statusSearch.length > 0
                            ? statusSearch == "true"
                              ? true
                              : false
                            : undefined,
                      })
                    );
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </MST.Container>
    </div>
  );
};

export default ServicePricesPage;
