import React from "react";
import "./style.css";
import { useContainerDimensions, useResponsiveWidth } from "../../../app/hooks";
import ReactModal from "react-modal";
function Modal({
  content,
  isShow,
  onHide,
  contentWidth = 400,
  contentStyle = {},
  classlist = "",
  classlistModalContent = "",
  width = 712,
}) {
  const { contentWidth: responsiveContentWidth } = useResponsiveWidth(contentWidth);
  return (
    <ReactModal
    isOpen={isShow}
    className="modal-wrapper"
    overlayClassName={classlist === "" ? "modal-overlay" : classlist}
  >
    <div className="modal-wrapper" onClick={onHide}></div>
    <div className={`modal-container max-sm:max-w-[96%]`} style={{ width }}>
      <div
        className={
          classlistModalContent === ""
            ? "modal-content"
            : classlistModalContent
        }
        style={{
          minWidth: responsiveContentWidth,
          ...contentStyle,
        }}
      >
        <div>{content}</div>
      </div>
    </div>
  </ReactModal>
  );
}

export default Modal;
